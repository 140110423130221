/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// this change is related to filtering work, with adding query params as a filter
// it is needed for page to not go up, after selecting tag to filter with
exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  return !(
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname &&
    routerProps.location.pathname === '/work'
  )
}
